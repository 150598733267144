import { ethers } from 'ethers'
import {
  Ether, CKB, BNB,
} from '../entities'
import { ChainData } from './types'

export const supportedChains: ChainData[] = [
  {
    name: 'GW (Test)',
    shortName: 'gw-testnet-v1',
    chain: 'CKB',
    network: 'gw-testnet',
    chainId: 71401,
    networkId: '71401',
    rpcURL: 'https://godwoken-testnet-v1.ckbapp.dev',
    explorerURL: 'https://gw-testnet-explorer.nervosdao.community',
    isTest: true,
    nativeCurrency: new CKB(71401),
    defaultTargetChainId: 4,
    bridgeAddress: '0x0000000000000000000000000000000000000000',
  },
  // {
  //   name: 'GW (Alpha)',
  //   shortName: 'gw-alphanet-v1',
  //   chain: 'CKB',
  //   network: 'gw-alphanet',
  //   chainId: 202206,
  //   networkId: '202206',
  //   rpcURL: 'https://gw-alphanet-v1.godwoken.cf/instant-finality-hack',
  //   explorerURL: 'https://gw-testnet-explorer.nervosdao.community',
  //   isTest: true,
  //   nativeCurrency: new CKB(202206),
  //   defaultTargetChainId: 4,
  //   bridgeAddress: '0x0000000000000000000000000000000000000000',
  // },
]

export const globalTokenLists = supportedChains.reduce((list, chain) => {
  if (!chain.dex?.tokenLists) return list
  list.push(...chain.dex.tokenLists)
  return list
}, [] as string[])
