import { ReactNode, useEffect } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import * as Sentry from '@sentry/react'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { ConnectorNames, connectorsByName } from '../helpers/connectors'
import { getLibrary, Web3RefetcherProvider } from './utils'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import {
  getAccountTransactions, isBridgeTx, LocalTx, LocalTxStatus, updateLocalTxByHash,
} from '../features/wallet/transactionSlice'

interface InjectorProps {
  children?: ReactNode
}

const PollInterval = 5 * 1000 // sec

const InnerProvider = ({ children }: InjectorProps): JSX.Element => {
  const network = connectorsByName[ConnectorNames.Refetcher]

  const {
    chainId, active, activate, library: provider,
  } = useWeb3React<Web3Provider>(ConnectorNames.Refetcher)

  const dispatch = useAppDispatch()
  const txs = useAppSelector(
    (state) => getAccountTransactions(state)
      .filter((tx) => !isBridgeTx(tx) && tx.status === LocalTxStatus.PENDING),
  ) as LocalTx[]

  useEffect(() => {
    (async () => {
      if (active && chainId) return
      await activate(network, undefined, true)
      console.log('Refetcher provider connected')
    })()
  }, [active, chainId, activate, network])

  useDeepCompareEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    const loop = async () => {
      for (let i = 0; i < txs.length; i += 1) {
        const tx = txs[i]
        // eslint-disable-next-line no-continue
        if (!tx.txHash) continue
        // eslint-disable-next-line no-continue
        if (tx.confirmations && tx.confirmations >= 1) continue

        network.changeChainId(tx.chainId)
        try {
          console.log(`Processing tx "${tx.txHash}" on chain "${tx.chainId}"...`)
          // eslint-disable-next-line no-await-in-loop
          const [receipt, block] = await Promise.all([
            provider?.getTransactionReceipt(tx.txHash),
            provider?.getBlock('latest'),
          ])
          if (!receipt) {
            console.log(`Tx "${tx.txHash}" not finished, skipping...`)
            // eslint-disable-next-line no-continue
            continue
          }
          if (!block) {
            console.log('Block not found, skipping...')
            // eslint-disable-next-line no-continue
            continue
          }

          if (block.number >= receipt.blockNumber + 1) {
            dispatch(updateLocalTxByHash({
              txHash: tx.txHash,
              confirmations: block.number - receipt.blockNumber,
              status: receipt.status,
            }))
            console.log(`Tx "${tx.txHash}" processed`)
          }
        } catch (e) {
          console.log(`Failed to process tx "${tx.txHash}" on chain "${tx.chainId}", error`, e)
          Sentry.captureException(e)
        }
      }
      timer = setTimeout(loop, PollInterval)
    }
    timer = setTimeout(loop, PollInterval)
    return () => {
      clearTimeout(timer)
    }
  }, [dispatch, network, provider, txs])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

const RefetcherProvider = ({ children }: InjectorProps): JSX.Element => (
  <Web3RefetcherProvider getLibrary={getLibrary}>
    <InnerProvider>
      {children}
    </InnerProvider>
  </Web3RefetcherProvider>
)

export default RefetcherProvider
