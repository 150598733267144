import { BigNumber } from 'ethers'
import { HookProvider } from '..'
import { useWeb3RequestQuery } from '../../features/api/web3ApiSlice'
import ITestGasslessNFT from '../../abi/ITestGasslessNFT.abi.json'

interface FetchUserListData {
  provider?: HookProvider
  contract: string
  from: string
}

export interface UserNFTData {
  tokenIds: BigNumber[]
  images: string[]
}

interface FetchUserListResult {
  data?: UserNFTData
  isLoading: boolean
  isFetching: boolean
}

const useFetchUserList = ({
  provider, contract, from,
}: FetchUserListData): FetchUserListResult => {
  const { data: response, isLoading, isFetching } = useWeb3RequestQuery({
    provider,
    request: {
      address: contract,
      abi: ITestGasslessNFT,
      method: 'userList',
      params: [{ from }],
    },
  }, {
    pollingInterval: 5_000,
  })

  return {
    data: response as (UserNFTData | undefined),
    isFetching,
    isLoading,
  }
}

export default useFetchUserList
