import { memo } from 'react'

import LoadingButton from '@mui/lab/LoadingButton'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { BigNumber, ethers } from 'ethers'
import { useAppSelector } from '../../app/hooks'
import { useTokenMint } from '../../hooks/nft'
import { prettyFormat } from '../../helpers/utilities'

interface ButtonProps {
  amount?: BigNumber
  contract?: string
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const DEFAULT_AMOUNT = ethers.utils.parseEther('1000')

const TokenMintButton = memo(({
  contract, amount = DEFAULT_AMOUNT, disabled = false, ...props
}: ButtonProps): JSX.Element => {
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const { library: provider } = useWeb3React<Web3Provider>()
  const { mint, isLoading: isMintLoading } = useTokenMint({
    provider,
    chainId,
    to: account,
    contract,
  })
  const isLoading = isMintLoading

  return (
    <LoadingButton
      loading={isLoading}
      onClick={() => mint({ to: account, amount })}
      style={{ textTransform: 'none' }}
      disabled={disabled}
      {...props}
    >
      {`Mint ${prettyFormat(amount, 18)} tokens`}
    </LoadingButton>
  )
})

export default TokenMintButton
