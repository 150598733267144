import { useState, useMemo } from 'react'
import { BigNumber, ethers } from 'ethers'

import useFetchMetamaskAPI from './useFetchMetamaskAPI'
import { HookProvider } from './types'
import IERC20ABI from '../abi/IERC20.abi.json'

import { useWeb3RequestQuery } from '../features/api/web3ApiSlice'

const DefaultPollingInterval = 5_000

interface NonceProps {
  provider?: HookProvider
  account: string
  pollingInterval?: number
  contract: string
}

interface NonceAction {
  data: BigNumber | null
  isSuccess: boolean
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

const useFetchNonce = ({
  provider,
  account,
  contract,
  pollingInterval = DefaultPollingInterval,
}: NonceProps): NonceAction => {
  const { data, ...queryData } = useWeb3RequestQuery({
    provider,
    request: {
      address: contract,
      abi: IERC20ABI,
      method: 'nonces',
      params: [account],
    },
  }, {
    pollingInterval,
    skip: !provider,
  })

  const response = useMemo(() => (!data ? null : data as BigNumber), [data])

  return {
    data: response,
    ...queryData,
  }
}

interface DomainData {
  name: string
  version: string
}

interface PermitProps {
  provider?: HookProvider
  chainId: number
  account: string
  contract: string
}

interface PermitRequest {
  spender: string
  domain: DomainData
  amount: BigNumber
  deadline: number
}

interface PermitAction {
  permit(data: PermitRequest): Promise<ethers.Signature | null>
  isLoading: boolean
}

const EIP712_DOMAIN_TYPE = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
]

const EIP2612_TYPE = [
  { name: 'owner', type: 'address' },
  { name: 'spender', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' },
]

const useERC20Permit = ({
  provider, chainId, account, contract,
}: PermitProps): PermitAction => {
  const [isLocked, setLock] = useState(false)

  const { signTypedData } = useFetchMetamaskAPI(provider)

  const {
    data: nonce, isSuccess, isFetching, isLoading,
  } = useFetchNonce({ provider, account, contract })

  const permit = async ({
    spender, domain: domainData, amount, deadline,
  }: PermitRequest): Promise<ethers.Signature | null> => {
    if (!isSuccess || isFetching || isLoading) return null

    if (!nonce) {
      console.log('nonce not loaded for:', account)
      return null
    }

    setLock(true)

    const domain = { ...domainData, chainId, verifyingContract: contract }

    const message = {
      owner: account,
      spender,
      value: amount.toString(),
      nonce: nonce.toString(),
      deadline,
    }

    const data = JSON.stringify({
      types: {
        EIP712Domain: EIP712_DOMAIN_TYPE,
        Permit: EIP2612_TYPE,
      },
      domain,
      primaryType: 'Permit',
      message,
    })

    const signature = await signTypedData(account, data)
    setLock(false)

    return signature
  }

  return {
    permit,
    isLoading: isLocked,
  }
}

export default useERC20Permit
