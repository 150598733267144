import { useState, useMemo, useEffect } from 'react'
import { ethers, BigNumber } from 'ethers'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import useFetchMetamaskAPI from '../useFetchMetamaskAPI'
import IERC20ABI from '../../abi/IERC20.abi.json'
import {
  getLocalTxByFilter,
  initializeTx, isBridgeTx, LocalTxStatus, updateLocalTxByHash,
} from '../../features/wallet/transactionSlice'
import { HookProvider, useWatchTx } from '..'

interface MintData {
  provider?: HookProvider
  chainId: number
  to: string
  contract?: string
}

interface MintRequest {
  to: string
  amount: BigNumber
}

interface MintAction {
  mint(params: MintRequest): Promise<string | undefined>
  isLoading: boolean
}

const useTokenMint = ({
  provider, chainId, to, contract,
}: MintData): MintAction => {
  const dispatch = useAppDispatch()

  const { callContract } = useFetchMetamaskAPI(provider)
  const [isLocked, setLock] = useState(false)
  const [currentTxHash, setTxHash] = useState<string | undefined>()

  const txFilter = useMemo(() => ([{
    from: to,
    to: contract,
    status: LocalTxStatus.PENDING,
    method: 'mint(address,uint256)',
  }]), [to, contract])
  const currentState = useAppSelector(getLocalTxByFilter(txFilter))

  // hook to check for current selected contract, in case of another, reset
  useEffect(() => {
    if (!contract) return

    if (!currentState && currentTxHash) {
      setTxHash(undefined)
    }
    if (currentState && currentState.txHash && currentTxHash !== currentState.txHash) {
      setTxHash(currentState.txHash)
    }
  }, [currentState, currentTxHash, contract])

  useWatchTx({
    provider,
    txHash: isBridgeTx(currentState) ? undefined : currentState?.txHash,
    confirmations: isBridgeTx(currentState) ? undefined : currentState?.confirmations,
    onSuccess: (receipt, confirmations) => {
      dispatch(updateLocalTxByHash({ txHash: receipt.transactionHash, confirmations, status: LocalTxStatus.SUCCESS }))
      setTxHash(undefined)
    },
    onError: (receipt, confirmations) => {
      dispatch(updateLocalTxByHash({ txHash: receipt.transactionHash, confirmations, status: LocalTxStatus.FAIL }))
      setTxHash(undefined)
    },
  })

  const mint = async ({
    to: mintTo, amount,
  }: MintRequest): Promise<string | undefined> => {
    if (isLocked || !contract) return undefined

    const method = 'mint(address,uint256)'
    const params = [mintTo, amount]
    const value = undefined

    setLock(true)

    const txHash = await callContract({
      from: to,
      value,
      contract: {
        address: contract,
        abi: IERC20ABI,
        method,
        params,
      },
    })
    if (txHash) {
      dispatch(initializeTx({
        txHash,
        chainId,
        from: to,
        to: contract,
        value: value || ethers.constants.Zero,
        status: LocalTxStatus.PENDING,
        abi: IERC20ABI,
        method,
        params,
        store: {
          update: true,
        },
      }))
    }
    setLock(false)
    return txHash
  }

  return {
    mint,
    isLoading: isLocked || currentState?.status === LocalTxStatus.PENDING,
  }
}

export default useTokenMint
