import { useMemo } from 'react'

import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, useTheme } from '@mui/material'

import { BigNumber, constants, utils } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { PageBox } from '../../boxes'
import {
  AddTokenButton, NFTMintButton, SmartConnectorWrapper, TokenMintButton,
} from '../../buttons'
import { useFetchUserList } from '../../../hooks/nft'
import { useAppSelector } from '../../../app/hooks'
import { ImgIcon } from '../../icons'
import { useIpfsQuery } from '../../../features/api/ipfsApiSlice'
import { getChainData } from '../../../helpers/utilities'
import { ChainData } from '../../../helpers/types'
import { DEFAULT_TOKEN_SYMBOL, NFT_CONTRACT_ADDRESS, PAYMENT_TOKEN_ADDRESS } from '../../../helpers/constants'
import { useFetchBalancePerCurrencies } from '../../../hooks'
import { getTestCurrency } from '../../../entities/utils'

const TOKEN_GAS_AMOUNT = utils.parseEther('10')

const NFTMetadataBlock = (
  {
    link, tokenId, chain, contract,
  }: { link: string, tokenId: BigNumber, chain: ChainData, contract: string },
): JSX.Element => {
  const ipfsHash = link.split('/')[link.split('/').length - 1]
  const { data: metadata, isLoading } = useIpfsQuery(ipfsHash)
  return (
    <Box p={1}>
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <Typography variant="subtitle2" mb={1}>{metadata?.name}</Typography>
          <Link
            color="secondary"
            sx={{ fontSize: 12 }}
            target="_blank"
            rel="noopener noreferrer"
            href={`${chain.explorerURL}/token/${contract}/instance/${tokenId}/`}
          >
            <ImgIcon src={metadata?.image} width={400} height={500} />
          </Link>
        </>
      )}
    </Box>
  )
}

const NFT = (): JSX.Element => {
  const theme = useTheme()
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const contract = NFT_CONTRACT_ADDRESS
  const { library: provider } = useWeb3React<Web3Provider>()
  const { data: nftData, isLoading: isNftDataLoading } = useFetchUserList({
    provider,
    contract,
    from: account,
  })
  const paymentToken = getTestCurrency(chainId, PAYMENT_TOKEN_ADDRESS)
  const chain = getChainData(chainId)
  const { data: balanceData } = useFetchBalancePerCurrencies({
    provider,
    pollingInterval: 5_000,
    fetchInfo: {
      currency: paymentToken,
      address: account,
    },
  })

  const balance = useMemo(() => {
    const amount = Array.isArray(balanceData) ? balanceData[0] : balanceData
    return amount || constants.Zero
  }, [balanceData])

  return (
    <PageBox mt={2}>
      <Grid
        container
        sx={{ maxWidth: 1024 }}
        spacing={{
          md: 2,
          xs: 0,
        }}
      >
        <Grid
          item
          xs={6}
          mb={2}
          sx={{ flexDirection: 'column' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ color: theme.palette.secondary.main }} mb={1}>
              1. Mint test tokens to pay for a gas!
            </Typography>
            <Box
              sx={{ maxWidth: 220 }}
            >
              <SmartConnectorWrapper
                checkDex
                variant="contained"
                color="secondary"
                size="small"
              >
                <TokenMintButton
                  disabled={balance.gte(TOKEN_GAS_AMOUNT)}
                  contract={paymentToken.address}
                />
              </SmartConnectorWrapper>
            </Box>
            {provider && (
              <Box
                sx={{ maxWidth: 220 }}
              >
                <SmartConnectorWrapper
                  checkDex
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  <AddTokenButton
                    currency={paymentToken}
                    fullWidth
                  />
                </SmartConnectorWrapper>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          mb={2}
          sx={{ flexDirection: 'column' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ color: theme.palette.secondary.main }} mb={1}>
              2. Mint your test NFT for free!
            </Typography>
            <Box
              sx={{ maxWidth: 220 }}
            >
              <SmartConnectorWrapper
                checkDex
                variant="contained"
                color="secondary"
                size="small"
              >
                <NFTMintButton
                  disabled={balance.lt(TOKEN_GAS_AMOUNT)}
                  contract={contract}
                />
              </SmartConnectorWrapper>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ color: theme.palette.secondary.main }} mb={1}>Your gasless NFTs</Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
            },
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            {isNftDataLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {nftData && chain && nftData.tokenIds.length > 0 ? (
                  <>
                    {nftData.tokenIds.map((tokenId, index) => (
                      <Box p={1}>
                        <NFTMetadataBlock
                          key={tokenId.toString()}
                          chain={chain}
                          link={nftData.images[index]}
                          tokenId={tokenId}
                          contract={contract}
                        />
                      </Box>
                    ))}
                  </>
                ) : (
                  <Typography variant="body2">No minted NFT at this moment</Typography>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </PageBox>
  )
}

export default NFT
