import React, {
  memo, useState, useEffect, useMemo, useCallback,
} from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { CircularProgress, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import ButtonGroup from '@mui/material/ButtonGroup'
import LaunchIcon from '@mui/icons-material/Launch'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { BigNumber } from 'ethers'

import { parseUnits } from '@ethersproject/units'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { closeModal, ModalType } from '../../features/modal/modalSlice'
import { deauthorize } from '../../features/wallet/walletSlice'
import { useFetchBridgeCurrencies, useFetchDexCurrencies } from '../../hooks'

import {
  getChainData, getGovernanceInfo, getShortAddress, prettyFormat,
} from '../../helpers/utilities'
import BootstrapDialogTitle from './BootstrapDialogTitle'
import {
  clearAllTxs,
  CrossChainTransferStatus,
  CrossChainTx,
  getAccountTransactions,
  isBridgeTx,
  LocalTx,
  LocalTxStatus,
} from '../../features/wallet/transactionSlice'
import { ChainData } from '../../helpers/types'
import { UnfinishedBridgeTxButton } from '../buttons'
import { DEFAULT_TOKEN_SYMBOL, PAYMENT_TOKEN_ADDRESS } from '../../helpers/constants'
import { Currency, WETH9 } from '../../entities'
import { ConnectorNames } from '../../helpers/connectors'
import { getNativeCurrency } from '../../hooks/utils'
import { IDOPool, useFetchIDOPools } from '../../hooks/launchpad'
import { globalTokenLists } from '../../helpers/chains'
import { getTestCurrency } from '../../entities/utils'

const CopyAddressLink = memo(({ address }: { address: string }): JSX.Element => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (!copied) return undefined

    const id = setTimeout(() => setCopied(false), 500)
    return () => {
      clearTimeout(id)
    }
  }, [copied])

  const { text, startIcon } = useMemo(() => ((copied) ? {
    text: 'Copied',
    startIcon: <CheckCircleOutlineIcon sx={{ color: 'green' }} />,
  } : {
    text: 'Copy address',
    startIcon: <ContentCopyIcon />,
  }), [copied])

  return (
    <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
      <Button
        color="secondary"
        startIcon={startIcon}
        sx={{
          textTransform: 'none',
        }}
      >
        {text}
      </Button>
    </CopyToClipboard>
  )
})
CopyAddressLink.displayName = 'CopyAddressLink'

const TxLink = ({ chain, txHash }: { chain: ChainData, txHash?: string }): JSX.Element => {
  if (!txHash) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{chain.name}</>
  }

  return (
    <Link
      color="secondary"
      sx={{ fontSize: 12 }}
      target="_blank"
      rel="noopener noreferrer"
      href={txHash && `${chain.explorerURL}/tx/${txHash}`}
    >
      {chain.name}
    </Link>
  )
}

const BridgeTxBox = ({ tx }: { tx: CrossChainTx }): JSX.Element | null => {
  const depositChain = useMemo(() => getChainData(tx.deposit.currency.chainId), [tx.deposit.currency.chainId])
  const withdrawChain = useMemo(() => getChainData(tx.withdraw.currency.chainId), [tx.withdraw.currency.chainId])

  const statusBlock = useMemo(() => {
    switch (tx.status) {
      case CrossChainTransferStatus.DONE:
        return <CheckCircleOutlineIcon sx={{ color: 'green', width: 20, height: 20 }} />
      default:
        return <CircularProgress size={18} color="secondary" />
    }
  }, [tx.status])

  const resumeBlock = useMemo(() => {
    switch (tx.status) {
      case CrossChainTransferStatus.DONE:
        return null
      default:
        return (
          <UnfinishedBridgeTxButton
            id={tx.id}
            onlyIcon={false}
            sx={{
              fontSize: 10,
              height: 24,
              minWidth: 72,
            }}
          />
        )
    }
  }, [tx.status, tx.id])
  if (!depositChain || !withdrawChain) return null

  return (
    <Grid container spacing={1} sx={{ paddingBottom: 1 }}>
      <Grid
        item
        xs={10}
        sx={{
          display: 'flex', justifyContent: 'flex-start', alignItems: 'top', flexDirection: 'row',
        }}
      >
        <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
          {`Transfer ${prettyFormat(tx.value, tx.deposit.currency.decimals)} ${tx.deposit.currency.symbol} from `}
          <TxLink chain={depositChain} txHash={tx.deposit.txHash} />
          {' to '}
          <TxLink chain={withdrawChain} txHash={tx.withdraw.txHash} />
        </Typography>
        {resumeBlock}
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'top' }}>
        {statusBlock}
      </Grid>
    </Grid>
  )
}

const LocalTxBox = ({
  tx, getIDOPool, getBridgeCurrency, getDexCurrency,
}: {
  tx: LocalTx,
  getBridgeCurrency: (chainId: number, addressOrSymbol?: string | undefined) => Currency | undefined,
  getDexCurrency: (chainId: number, addressOrSymbol?: string | undefined) => Currency | undefined,
  getIDOPool: (chainId: number, address: string) => IDOPool | undefined,
}): JSX.Element | null => {
  const chain = useMemo(() => getChainData(tx.chainId), [tx.chainId])

  const statusBlock = useMemo(() => {
    switch (tx.status) {
      case LocalTxStatus.FAIL:
        return <ErrorOutlineIcon sx={{ color: 'red', width: 20, height: 20 }} />
      case LocalTxStatus.SUCCESS:
        return <CheckCircleOutlineIcon sx={{ color: 'green', width: 20, height: 20 }} />
      case LocalTxStatus.PENDING:
      default:
        return <CircularProgress size={18} color="secondary" />
    }
  }, [tx.status])

  const weth9 = WETH9[tx.chainId]

  const textBlock = useMemo(() => {
    if (!chain) return null

    switch (tx.method) {
      case 'approve': {
        const currency = getBridgeCurrency(tx.chainId, tx.to) || getDexCurrency(tx.chainId, tx.to)
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Approve${(currency) ? ` ${currency?.symbol}` : ''} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'getReward': {
        const { currency } = getGovernanceInfo(tx.chainId)
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Claim reward${(currency) ? ` ${currency?.symbol}` : ''} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'exit': {
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            Exit from pool on
            {' '}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'deposit': {
        if (tx.to.toLowerCase() === weth9.address.toLowerCase()) {
          const native = getNativeCurrency(tx.chainId)
          const amount = tx.value as BigNumber
          return (
            <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
              {`Wrap ${prettyFormat(amount, weth9.decimals)} ${native?.symbol} on `}
              <TxLink chain={chain} txHash={tx.txHash} />
            </Typography>
          )
        }
        return null
      }
      case 'withdraw': {
        if (tx.to.toLowerCase() === weth9.address.toLowerCase()) {
          const native = getNativeCurrency(tx.chainId)
          const amount = tx.params[0] as BigNumber
          return (
            <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
              {`Unwrap ${prettyFormat(amount, native?.decimals)} ${native?.symbol} on `}
              <TxLink chain={chain} txHash={tx.txHash} />
            </Typography>
          )
        }
        const { currency } = getGovernanceInfo(tx.chainId)
        const amount = tx.params[0] as BigNumber
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Withdraw ${prettyFormat(amount, currency?.decimals)} from pool on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'stake': {
        const { currency } = getGovernanceInfo(tx.chainId)
        const amount = tx.params[0] as BigNumber
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Stake ${prettyFormat(amount, currency?.decimals)} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'addLiquidity': {
        const amount0 = tx.params[2] as BigNumber
        const currency0 = getDexCurrency(tx.chainId, tx.params[0] as string)
        const amount1 = tx.params[3] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, tx.params[1] as string)
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Add liquidity ${prettyFormat(amount0, currency0?.decimals)} ${currency0?.symbol} `}
            {`and ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'addLiquidityETH': {
        const amount0 = tx.value
        const currency0 = getDexCurrency(tx.chainId, DEFAULT_TOKEN_SYMBOL)
        const amount1 = tx.params[1] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, tx.params[0] as string)
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Add liquidity ${prettyFormat(amount0, currency0?.decimals)} ${DEFAULT_TOKEN_SYMBOL} `}
            {`and ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'swapExactETHForTokens': {
        const path = tx.params[1] as [string, string]
        const amount0 = tx.value
        const currency0 = getDexCurrency(tx.chainId, DEFAULT_TOKEN_SYMBOL)
        const amount1 = tx.params[0] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, path[1])
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Swap exact ${prettyFormat(amount0, currency0?.decimals)} ${DEFAULT_TOKEN_SYMBOL} `}
            {`for ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'swapETHForExactTokens': {
        const path = tx.params[1] as [string, string]
        const amount0 = tx.value
        const currency0 = getDexCurrency(tx.chainId, DEFAULT_TOKEN_SYMBOL)
        const amount1 = tx.params[0] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, path[1])
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Swap ${prettyFormat(amount0, currency0?.decimals)} ${DEFAULT_TOKEN_SYMBOL} `}
            {`for exact ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'swapExactTokensForETH': {
        const path = tx.params[2] as [string, string]
        const amount0 = tx.params[0] as BigNumber
        const currency0 = getDexCurrency(tx.chainId, path[0])
        const amount1 = tx.params[1] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, DEFAULT_TOKEN_SYMBOL)
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Swap exact ${prettyFormat(amount0, currency0?.decimals)} ${currency0?.symbol} `}
            {`for ${prettyFormat(amount1, currency1?.decimals)} ${DEFAULT_TOKEN_SYMBOL} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'swapTokensForExactETH': {
        const path = tx.params[2] as [string, string]
        const amount0 = tx.params[1] as BigNumber
        const currency0 = getDexCurrency(tx.chainId, path[0])
        const amount1 = tx.params[0] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, DEFAULT_TOKEN_SYMBOL)
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Swap ${prettyFormat(amount0, currency0?.decimals)} ${currency0?.symbol} `}
            {`for exact ${prettyFormat(amount1, currency1?.decimals)} ${DEFAULT_TOKEN_SYMBOL} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'swapExactTokensForTokens': {
        const path = tx.params[2] as [string, string]
        const amount0 = tx.params[0] as BigNumber
        const currency0 = getDexCurrency(tx.chainId, path[0])
        const amount1 = tx.params[1] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, path[1])
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Swap exact ${prettyFormat(amount0, currency0?.decimals)} ${currency0?.symbol} `}
            {`for ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'swapTokensForExactTokens': {
        const path = tx.params[2] as [string, string]
        const amount0 = tx.params[1] as BigNumber
        const currency0 = getDexCurrency(tx.chainId, path[0])
        const amount1 = tx.params[0] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, path[1])
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Swap ${prettyFormat(amount0, currency0?.decimals)} ${currency0?.symbol} `}
            {`for exact ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'removeLiquidityETH': {
        const currency0 = getDexCurrency(tx.chainId, DEFAULT_TOKEN_SYMBOL)
        const amount0 = tx.params[3] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, tx.params[0] as string)
        const amount1 = tx.params[2] as BigNumber
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Remove liquidity ${prettyFormat(amount0, currency0?.decimals)} ${DEFAULT_TOKEN_SYMBOL} `}
            {`and ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'removeLiquidity': {
        const currency0 = getDexCurrency(tx.chainId, tx.params[0] as string)
        const amount0 = tx.params[3] as BigNumber
        const currency1 = getDexCurrency(tx.chainId, tx.params[1] as string)
        const amount1 = tx.params[4] as BigNumber
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Remove liquidity ${prettyFormat(amount0, currency0?.decimals)} ${currency0?.symbol} `}
            {`and ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'buyTokens()': {
        const idoPool = getIDOPool(tx.chainId, tx.to)
        if (!idoPool) return null

        const currency0 = idoPool.sellToken
        const currency1 = idoPool.buyToken
        const amount0 = tx.value as BigNumber
        const amount1 = idoPool.amounts.rate.mul(amount0).div(parseUnits('1', 6)) as BigNumber
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`IDO buy ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} `}
            {`for ${prettyFormat(amount0, currency0?.decimals)} ${currency0.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'buyTokens(uint256)': {
        const idoPool = getIDOPool(tx.chainId, tx.to)
        if (!idoPool) return null

        const currency0 = idoPool.sellToken
        const currency1 = idoPool.buyToken
        const amount0 = tx.params[0] as BigNumber
        const amount1 = idoPool.amounts.rate.mul(amount0).div(parseUnits('1', 6))
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`IDO buy ${prettyFormat(amount1, currency1?.decimals)} ${currency1?.symbol} `}
            {`for ${prettyFormat(amount0, currency0?.decimals)} ${currency0.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'mint(string)': {
        const ipfsUri = 'https://gateway.pinata.cloud/ipfs/'
        const ipfsHash = tx.params[0] as string
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            <Link
              color="secondary"
              sx={{ fontSize: 12 }}
              target="_blank"
              rel="noopener noreferrer"
              href={`${ipfsUri}${ipfsHash}`}
            >
              NFT
            </Link>
            {' mint on '}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      case 'mint(address,uint256)': {
        const to = tx.params[0] as string
        const amount = tx.params[1] as BigNumber
        // NOTE: Hardcoded only for demo
        const currency = getTestCurrency(tx.chainId, PAYMENT_TOKEN_ADDRESS)
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`ERC20 mint ${prettyFormat(amount, currency.decimals)} ${currency.symbol} `}
            {`to ${to} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      default:
        return null
    }
  }, [
    chain, getIDOPool, getBridgeCurrency, getDexCurrency,
    tx.chainId, tx.method, tx.to, tx.txHash, tx.params, tx.value, weth9,
  ])

  if (!textBlock) return null

  return (
    <Grid container spacing={1} sx={{ paddingBottom: 1 }}>
      <Grid
        item
        xs={10}
        sx={{
          display: 'flex', justifyContent: 'flex-start', alignItems: 'top', flexDirection: 'row',
        }}
      >
        {textBlock}
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'top' }}>
        {statusBlock}
      </Grid>
    </Grid>
  )
}

const TransactionContent = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const txs = useAppSelector(getAccountTransactions)
  const onClearAll = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    dispatch(clearAllTxs())
  }
  const { getCurrency: getBridgeCurrency, isLoading: isBridgeLoading } = useFetchBridgeCurrencies()
  const { getCurrency: getDexCurrency, isLoading: isDexLoading } = useFetchDexCurrencies(globalTokenLists)
  const { getIDOPool, isLoading: isIDOLoading } = useFetchIDOPools({ filter: 'skip' })

  const isLoading = false

  return (
    <Grid container spacing={3} sx={{ p: 1, paddingLeft: 2, paddingRight: 2 }}>
      {!txs.length ? (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>Your transactions will appear here...</Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>Recent Transactions</Typography>
            <Link href="/#" onClick={onClearAll} sx={{ fontSize: 12 }} color="secondary">(clear all)</Link>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="secondary" />
              </Box>
            ) : (
              <>
                {txs.map((tx) => (
                  isBridgeTx(tx)
                    ? <BridgeTxBox key={tx.id} tx={tx} />
                    : (
                      <LocalTxBox
                        key={tx.id}
                        tx={tx}
                        getBridgeCurrency={getBridgeCurrency}
                        getDexCurrency={getDexCurrency}
                        getIDOPool={getIDOPool}
                      />
                    )
                ))}
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
}

const WalletModal = (): JSX.Element => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal[ModalType.WALLET])

  const { account, chainId, connector } = useAppSelector((state) => state.wallet)
  const { deactivate } = useWeb3React<Web3Provider>()

  const chain = getChainData(chainId)

  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch])
  const handleDisconnect = () => {
    dispatch(deauthorize(true))
    deactivate()
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="wallet-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <BootstrapDialogTitle id="wallet-dialog-title" onClose={handleClose}>
        Account
      </BootstrapDialogTitle>
      <DialogContent>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            backgroundColor: 'unset',
          }}
        >
          {account && chain && (
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography gutterBottom sx={{ fontSize: 14 }}>
                    Connected with
                    {' '}
                    {connector === ConnectorNames.Injected ? 'MetaMask' : connector}
                  </Typography>
                  <ButtonGroup
                    variant="outlined"
                    color="warning"
                    size="small"
                    sx={{
                      p: 0,
                      marginLeft: 1,
                    }}
                  >
                    <Button
                      onClick={handleDisconnect}
                    >
                      Disconnect
                    </Button>
                  </ButtonGroup>
                </Box>
                <Typography
                  color="secondary"
                  sx={{ fontWeight: 600 }}
                >
                  {getShortAddress(account)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CopyAddressLink address={account} />
                <Button
                  color="secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<LaunchIcon />}
                  href={`${chain.explorerURL}/address/${account}`}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  View on Explorer
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </DialogContent>
      <DialogContent
        sx={{
          backgroundColor: (theme.palette.mode === 'light') ? '#f7f8fa' : '#1b1b1b',
          maxHeight: 300,
        }}
      >
        <TransactionContent />
      </DialogContent>
    </Dialog>
  )
}

export default WalletModal
