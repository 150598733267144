import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

interface IPFSResponse {
  name: string
  image: string
  attributes: Array<unknown>
}

const ipfsApiSlice = createApi({
  reducerPath: 'ipfsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://gateway.pinata.cloud/ipfs/',
  }),
  endpoints: (builder) => ({
    ipfs: builder.query<IPFSResponse, string>({
      query: (ipfsHash) => (ipfsHash),
    }),
  }),
})

export const {
  useIpfsQuery,
} = ipfsApiSlice

export default ipfsApiSlice
