import { memo } from 'react'

import LoadingButton from '@mui/lab/LoadingButton'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { BigNumber, ethers } from 'ethers'
import { useAppSelector } from '../../app/hooks'
import { useGasslessMint } from '../../hooks/nft'
import useERC20Permit from '../../hooks/userERC20Permit'
import { PAYMASTER_ADDRESS, PAYMENT_TOKEN_ADDRESS } from '../../helpers/constants'

interface ButtonProps {
  contract?: string
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const NFTMintButton = memo(({ contract, disabled = false, ...props }: ButtonProps): JSX.Element => {
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const { library: provider } = useWeb3React<Web3Provider>()
  const { permit, isLoading: isPermitLoading } = useERC20Permit({
    provider,
    chainId,
    account,
    contract: PAYMENT_TOKEN_ADDRESS,
  })
  const { mint, isLoading: isMintLoading } = useGasslessMint({
    provider,
    chainId,
    to: account,
    contract,
  })
  const isLoading = isPermitLoading || isMintLoading

  const mintWithPermit = async () => {
    const deadline = Math.floor(Date.now() / 1000) + 60 * 60 // 1 hour
    const signatures = await permit({
      spender: PAYMASTER_ADDRESS,
      domain: { name: 'Gasless Token', version: '1' },
      amount: ethers.utils.parseEther('10'),
      deadline,
    })
    if (!signatures) {
      console.log('signatures not created, abort')
      return
    }

    const paymasterData = ethers.utils.RLP.encode([
      ethers.utils.hexlify(deadline),
      ethers.utils.hexlify(signatures.v),
      signatures.r,
      signatures.s,
    ]).slice(2)
    await mint(paymasterData)
  }
  return (
    <LoadingButton
      loading={isLoading}
      onClick={() => mintWithPermit()}
      style={{ textTransform: 'none' }}
      disabled={disabled}
      {...props}
    >
      {disabled ? 'Not claimable' : 'Mint NFT'}
    </LoadingButton>
  )
})

export default NFTMintButton
