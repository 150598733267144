import { useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'
import * as Sentry from '@sentry/react'

import { ethers, BigNumber } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'

import { splitSignature } from 'ethers/lib/utils'
import { Currency } from '../entities'
import { CustomExternalProvider } from '../providers/utils'
import { sleep } from '../helpers/utilities'
import { ChainInfo } from '../features/api/chainListApiSlice'

interface ContractPayload {
  address: string
  abi: ethers.ContractInterface
  method: string
  params?: Array<unknown>
}

interface TxPayload {
  from: string
  to?: string
  contract?: ContractPayload
  value?: BigNumber
  gas?: BigNumber
  gasPrice?: BigNumber
}

interface MetamaskError {
  code: number
  message: string
}

interface MetaMaskAPI {
  isLoading: boolean
  isError: boolean
  error?: string
  addToken(currency: Currency): Promise<void>
  signMessage(msg: string, address: string): Promise<string | null>
  signTypedData(address: string, data: string): Promise<ethers.Signature | null>
  switchNetwork(chainId: number): Promise<boolean>
  callContract(request: TxPayload): Promise<string | undefined>
}

export async function getContractData({
  address, abi, method, params = [],
}: ContractPayload): Promise<string | undefined> {
  const contract = new ethers.Contract(address, abi)
  const unsignedTx = await contract.populateTransaction[method](...params)
  return unsignedTx.data
}

const useFetchMetamaskAPI = (provider?: Web3Provider): MetaMaskAPI => {
  const [error, setError] = useState<string | undefined>()
  const [isLoading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  // let { data: chainList = [] } = useChainListQuery()

  const [chainList] = useState<ChainInfo[]>([{
    name: 'gw-alpha',
    chain: 'gwa',
    network: 'gwa',
    icon: 'test',
    rpc: [
      'https://gw-alphanet-v1.godwoken.cf/instant-finality-hack',
    ],
    faucets: [],
    nativeCurrency: {
      name: 'pCKB',
      symbol: 'pCKB',
      decimals: '18',
    },
    infoURL: 'test',
    shortName: 'gwa',
    chainId: 202206,
    networkId: 202206,
    explorers: [
      {
        name: 'gwa',
        url: 'https://gw-testnet-explorer.nervosdao.community',
        standard: 'eip',
      },
    ],
  }])

  const ethereum = (provider) ? provider.provider as CustomExternalProvider : window.ethereum

  const getChain = useCallback(
    (chainId: number) => chainList.filter((chain) => chain.chainId === chainId)[0],
    [chainList],
  )

  const callContract = useCallback(async ({
    from,
    to: destination,
    value,
    contract,
    gas,
    gasPrice,
  }: TxPayload): Promise<string | undefined> => {
    setError(undefined)
    if (isLoading) return undefined

    let to: string | undefined
    let data: string | undefined

    try {
      setLoading(true)
      if (contract) {
        data = await getContractData(contract)
        to = contract.address
      } else {
        to = destination
      }
      const transactionParameters = {
        from,
        to,
        data,
        value: (value) ? `0x${value.toBigInt().toString(16)}` : undefined,
        gas: (gas) ? `0x${gas.toBigInt().toString(16)}` : undefined,
        gasPrice: (gasPrice) ? `0x${gasPrice.toBigInt().toString(16)}` : undefined,
      }
      console.log('transactionParameters', transactionParameters)
      const txHash = await ethereum.request?.({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      enqueueSnackbar(`Tx "${txHash}" has been submited!`, { variant: 'success' })
      return txHash
    } catch (e) {
      const errMsg = (e as Error).message
      enqueueSnackbar(errMsg, { variant: 'error' })
      setError(errMsg)
      Sentry.captureException(e)
      return undefined
    } finally {
      setLoading(false)
    }
  }, [ethereum, enqueueSnackbar, isLoading])

  const signMessage = useCallback(async (msg: string, address: string): Promise<string | null> => {
    setError(undefined)
    if (!ethereum) {
      const errMsg = 'MetaMask has not been installed!'
      enqueueSnackbar(errMsg, { variant: 'error' })
      setError(errMsg)
      return null
    }
    if (isLoading) return null

    try {
      return await ethereum.request?.({
        method: 'personal_sign',
        params: [msg, address, ''],
      })
    } catch (e) {
      const errMsg = (e as Error).message
      enqueueSnackbar(errMsg, { variant: 'error' })
      setError(errMsg)
      Sentry.captureException(e)
      return null
    } finally {
      setLoading(false)
    }
  }, [ethereum, enqueueSnackbar, isLoading])

  const signTypedData = useCallback(async (address: string, data: string): Promise<ethers.Signature | null> => {
    setError(undefined)
    if (!ethereum) {
      const errMsg = 'MetaMask has not been installed!'
      enqueueSnackbar(errMsg, { variant: 'error' })
      setError(errMsg)
      return null
    }

    if (isLoading) return null

    try {
      const rawSignature = await ethereum.request?.({
        method: 'eth_signTypedData_v4',
        params: [address, data],
      })
      return splitSignature(rawSignature)
    } catch (e) {
      const errMsg = (e as Error).message
      enqueueSnackbar(errMsg, { variant: 'error' })
      setError(errMsg)
      Sentry.captureException(e)
      return null
    } finally {
      setLoading(false)
    }
  }, [ethereum, enqueueSnackbar, isLoading])

  const switchNetwork = useCallback(async (chainId: number): Promise<boolean> => {
    setError(undefined)
    if (!ethereum) {
      const errMsg = 'MetaMask has not been installed!'
      enqueueSnackbar(errMsg, { variant: 'error' })
      setError(errMsg)
      return false
    }

    if (isLoading) return false

    const switchChain = getChain(chainId)
    if (!switchChain) {
      const errMsg = `Chain "${chainId}" not found, abort`
      enqueueSnackbar(errMsg, { variant: 'error' })
      setError(errMsg)
      return false
    }

    const hexChainId = `0x${chainId.toString(16)}`
    try {
      setLoading(true)
      await ethereum.request?.({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      })
      enqueueSnackbar(`"${switchChain.name}" connected`, { variant: 'success' })
      return true
    } catch (switchError) {
      let err = switchError as MetamaskError
      // This error code indicates that the chain has not been added to MetaMask.
      if (err.code === 4902 || err.code === -32603) {
        try {
          await ethereum.request?.({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: hexChainId,
              chainName: switchChain.name,
              nativeCurrency: {
                name: switchChain.nativeCurrency.name,
                symbol: switchChain.nativeCurrency.symbol,
                decimals: switchChain.nativeCurrency.decimals,
              },
              rpcUrls: [
                switchChain.rpc[0],
              ],
              blockExplorerUrls: [
                switchChain.explorers[0]?.url,
              ],
              iconUrls: [],
            }],
          })
          enqueueSnackbar(`"${switchChain.name}" connected`, { variant: 'success' })
          return true
        } catch (addError) {
          Sentry.captureException(addError)
          err = addError as MetamaskError
        }
      } else {
        Sentry.captureException(switchError)
      }
      if (!ethereum.isMetaMask && err.message.includes('wallet_switchEthereumChain')) {
        enqueueSnackbar(
          'the method wallet_switchEthereumChain is not available. '
          + 'Please change/add network connection mannualy',
          { variant: 'error' },
        )
      } else {
        enqueueSnackbar(err.message, { variant: 'error' })
      }
      setError(err.message)
      return false
    } finally {
      setLoading(false)
    }
  }, [ethereum, enqueueSnackbar, isLoading, getChain])

  const addToken = useCallback(async (currency: Currency): Promise<void> => {
    setError(undefined)
    if (!ethereum) {
      enqueueSnackbar('MetaMask has not been installed!', { variant: 'error' })
      return
    }
    // eslint-disable-next-line no-underscore-dangle
    let { chainId: currentChainId } = ethereum as unknown as { chainId: number }
    // NOTE: Sometimes it is hex, ugly(
    if (typeof currentChainId === 'string') {
      currentChainId = parseInt((currentChainId as string).slice(2), 16)
    }
    // in case token in another network, we should switch first
    if (currency.chainId !== currentChainId) {
      const success = await switchNetwork(currency.chainId)
      if (!success) {
        return
      }
      // should wait to show popup
      await sleep(1000)
    } else {
      if (isLoading) return
      setLoading(true)
    }

    try {
      // NOTE: should return success, but has a bug
      // https://github.com/MetaMask/metamask-extension/issues/12416#issuecomment-965096575
      // so skip the check on success right now
      await ethereum.request?.({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: currency.address,
            symbol: currency.symbol,
            decimals: currency.decimals,
            image: currency.logoURI,
          },
        },
      })
    } catch (e) {
      const errMsg = (e as Error).message
      enqueueSnackbar(errMsg, { variant: 'error' })
      setError(errMsg)
      Sentry.captureException(e)
    } finally {
      setLoading(false)
    }
  }, [ethereum, switchNetwork, enqueueSnackbar, isLoading])

  return {
    isError: !!error,
    isLoading,
    error,
    addToken,
    signMessage,
    signTypedData,
    switchNetwork,
    callContract,
  }
}

export default useFetchMetamaskAPI
