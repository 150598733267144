import { useState, useMemo, useEffect } from 'react'
import { BigNumber, ethers } from 'ethers'
import { v4 as uuidv4 } from 'uuid'

import axios from 'axios'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import useFetchMetamaskAPI, { getContractData } from '../useFetchMetamaskAPI'
import ITestGasslessNFT from '../../abi/ITestGasslessNFT.abi.json'
import IGasslessEntryPoint from '../../abi/IGasslessEntryPoint.abi.json'
import {
  getLocalTxByFilter,
  initializeTx, isBridgeTx, LocalTxStatus, updateLocalTxByHash,
} from '../../features/wallet/transactionSlice'
import { HookProvider, useWatchTx } from '..'
import { GASLESS_ENTRYPOINT_ADDRESS, PAYMASTER_ADDRESS } from '../../helpers/constants'

interface MintData {
  provider?: HookProvider
  chainId: number
  to: string
  contract?: string
}

interface MintAction {
  mint(paymasterData?: string): Promise<string | undefined>
  isLoading: boolean
  error?: string
}

interface UserOperation {
  callContract: string // address of the target contract
  callData: string // call data of the target contract
  callGasLimit?: BigNumber // gas used to execute the call
  verificationGasLimit?: BigNumber // gas used to verification
  maxFeePerGas?: BigNumber // gas price
  maxPriorityFeePerGas?: BigNumber // must equals to maxFeePerGas, reserved for EIP - 1559
  paymasterAndData?: string // pay master address and extra data
}

const prepareUserOps = ({
  callContract, callData, callGasLimit = BigNumber.from('2000000'), verificationGasLimit = BigNumber.from('2000000'),
  maxFeePerGas = BigNumber.from('60'), maxPriorityFeePerGas = BigNumber.from('60'),
  paymasterAndData = PAYMASTER_ADDRESS,
}: UserOperation) => ([{
  callContract,
  callData,
  callGasLimit,
  verificationGasLimit,
  maxFeePerGas,
  maxPriorityFeePerGas,
  paymasterAndData,
}])

interface NFTtoIPFSData {
  name: string
  image: string
  attributes: Array<unknown>
}

const sendNFTToIPFS = async (data: NFTtoIPFSData) => {
  const resFile = await axios({
    method: 'post',
    url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    data: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
      'Content-Type': 'application/json',
    },
  })
  return resFile.data.IpfsHash
}

const useGasslessMint = ({
  provider, chainId, to, contract,
}: MintData): MintAction => {
  const dispatch = useAppDispatch()

  const { callContract } = useFetchMetamaskAPI(provider)
  const [isLocked, setLock] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [currentTxHash, setTxHash] = useState<string | undefined>()

  const txFilter = useMemo(() => ([{
    from: to,
    to: contract,
    status: LocalTxStatus.PENDING,
    method: 'mint(string)',
  }]), [to, contract])
  const currentState = useAppSelector(getLocalTxByFilter(txFilter))

  // hook to check for current selected contract, in case of another, reset
  useEffect(() => {
    if (!contract) return

    if (!currentState && currentTxHash) {
      setTxHash(undefined)
    }
    if (currentState && currentState.txHash && currentTxHash !== currentState.txHash) {
      setTxHash(currentState.txHash)
    }
  }, [currentState, currentTxHash, contract])

  useWatchTx({
    provider,
    txHash: isBridgeTx(currentState) ? undefined : currentState?.txHash,
    confirmations: isBridgeTx(currentState) ? undefined : currentState?.confirmations,
    onSuccess: (receipt, confirmations) => {
      dispatch(updateLocalTxByHash({ txHash: receipt.transactionHash, confirmations, status: LocalTxStatus.SUCCESS }))
      setTxHash(undefined)
    },
    onError: (receipt, confirmations) => {
      dispatch(updateLocalTxByHash({ txHash: receipt.transactionHash, confirmations, status: LocalTxStatus.FAIL }))
      setTxHash(undefined)
    },
  })

  const mint = async (paymasterData = ''): Promise<string | undefined> => {
    if (isLocked || !contract) return undefined

    setLock(true)
    setError(undefined)

    const generatedHash = uuidv4()
    let ipfsHash: string

    try {
      ipfsHash = await sendNFTToIPFS({
        name: `Demo gassless NFT ${generatedHash}`,
        image: `https://picsum.photos/seed/${generatedHash}/400/500`,
        attributes: [],
      })
    } catch (e) {
      console.log('failed to send to IPFS, details', e)
      setError('IPFS send failure')
      return undefined
    }
    console.log('ipfsHash', ipfsHash)

    const method = 'handleOp((address,bytes,uint256,uint256,uint256,uint256,bytes))'

    const callData = await getContractData({
      address: contract,
      abi: ITestGasslessNFT,
      method: 'mint(string)',
      params: [ipfsHash],
    })
    if (!callData) {
      setError('callData prepare failure')
      return undefined
    }
    const params: Array<UserOperation> = prepareUserOps({
      callContract: contract,
      callData,
      paymasterAndData: `${PAYMASTER_ADDRESS}${paymasterData}`,
    })
    console.log('params', params)
    const value = ethers.constants.Zero

    const txHash = await callContract({
      from: to,
      value,
      contract: {
        address: GASLESS_ENTRYPOINT_ADDRESS,
        abi: IGasslessEntryPoint,
        method,
        params,
      },
      gas: BigNumber.from('8000000'),
      gasPrice: BigNumber.from('0'),
    })
    if (txHash) {
      dispatch(initializeTx({
        txHash,
        chainId,
        from: to,
        to: contract,
        value: value || ethers.constants.Zero,
        status: LocalTxStatus.PENDING,
        abi: ITestGasslessNFT,
        method: 'mint(string)',
        params: [ipfsHash],
        store: {
          update: true,
        },
      }))
    }
    setLock(false)
    return txHash
  }

  return {
    mint,
    error,
    isLoading: isLocked || currentState?.status === LocalTxStatus.PENDING,
  }
}

export default useGasslessMint
